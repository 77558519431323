<template>
  <v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="1200px">
        <v-card>
          <v-card-title>
            <span class="headline">Nueva Cuenta contable</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" :lazy-validation="false">
                <v-alert
                  type="warning"
                  v-if="showGeneralError"
                  border="left"
                  dismissible
                  >{{ message }}</v-alert
                >
                <v-row>
                  <v-col cols="12" md="6">
                    <v-select v-model="level" :items="[2, 3, 4]" label="Nivel"></v-select>
                    <small class="red--text text--lighten-1" v-if="levelError">{{
                      levelMessageError
                    }}</small>
                  </v-col>
                  <v-col cols="12" md="6">
                    <Classification
                      v-model="classification"
                      :required="true"
                      :disabled="false"
                      :itemText="true"
                      :toLevelFilter="level"
                      :toInvoices="false"
                    ></Classification>
                    <small class="red--text text--lighten-1" v-if="classificationError">{{
                      classificationMessageError
                    }}</small>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="body.name"
                      :rules="onlyRequired"
                      label="Nombre de la cuenta"
                    ></v-text-field>
                    <small class="red--text text--lighten-1" v-if="nameError">{{
                      nameMessageError
                    }}</small>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="body.single_code"
                      :rules="onlyRequired"
                      label="Código"
                    ></v-text-field>
                    <small class="red--text text--lighten-1" v-if="singleError">{{
                      singleMessageError
                    }}</small>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <p class="subtitle-1 font-weight-black">
                      {{ body.name.toUpperCase() }} {{ classification.code }}-{{
                        body.single_code
                      }}
                    </p>
                    <small class="red--text text--lighten-1" v-if="codeError">{{
                      codeMessageError
                    }}</small>
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="text-right">
                      <v-btn
                        :disabled="loading"
                        color="warning"
                        class="ma-2 white--text"
                        @click="close"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        :loading="loading"
                        :disabled="loading"
                        color="success"
                        @click="save"
                      >
                        Guardar
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import requests from "@/services/requests.js";
import rules from "@/utils/form.rules.js";
export default {
  components: {
    Classification: () => import("@/components/accounting/ClassificationField"),
  },
  props: {
    dialog: { type: Boolean, required: true },
  },
  data() {
    return {
      loading: false,
      valid: false,
      classification: {},
      level: 4,
      body: {
        name: "",
        classification_id: "",
        single_code: "",
        level: "",
      },
      //rules
      textRules: [rules.required, rules.minLength(5)],
      onlyRequired: [rules.required],
      decimalRules: [rules.decimal, rules.required, rules.minValue(0.01)],
      //   errors
      showGeneralError: false,
      message: "",
      codeError: false,
      codeMessageError: "",
      classificationError: false,
      classificationMessageError: "",
      levelError: false,
      levelMessageError: "",
      nameError: false,
      nameMessageError: "",
      singleError: false,
      singleMessageError: "",
    };
  },
  methods: {
    save() {
      this.body.level = this.level;
      this.loading = true;
      requests.post("/api/accounting/classification/", this.body).then((res) => {
        if (res.status == 201) {
          this.close();
          this.$toasted.global.info({
              message: "Registro creado",
            });
        } else if (res.status == 400) {
          if ("code" in res.data) {
            this.codeError = true;
            this.codeMessageError = res.data.code;
          } else {
            this.codeError = false;
            this.codeMessageError = "";
          }

          if ("classification_id" in res.data) {
            this.classificationError = true;
            this.classificationMessageError = res.data.classification_id[0];
          } else {
            this.classificationError = false;
            this.classificationMessageError = "";
          }

          if ("level" in res.data) {
            this.levelError = true;
            this.levelMessageError = "";
          } else {
            this.levelError = false;
            this.levelMessageError = "";
          }

          if ("name" in res.data) {
            this.nameError = true;
            this.nameMessageError = res.data.name[0];
          } else {
            this.nameError = false;
            this.nameMessageError = "";
          }

          if ("single_code" in res.data) {
            this.singleError = true;
            this.singleMessageError = res.data.single_code[0];
          } else {
            this.singleError = false;
            this.singleMessageError = "";
          }
        } else {
          this.showGeneralError = true;
          this.message = res.data.detail;
        }
        this.loading = false;
      });
    },
    close() {
      this.$emit("close");
      this.body = {
        name: "",
        classification_id: "",
        single_code: "",
      };
      this.loading = false;
      this.valid = false;
      this.classification = {};
      this.level = 4;
      // reset errors
      this.codeError = false;
      this.codeMessageError = "";
      this.classificationError = false;
      this.classificationMessageError = "";
      this.levelError = false;
      this.levelMessageError = "";
      this.nameError = false;
      this.nameMessageError = "";
      this.singleError = false;
      this.singleMessageError = "";
      this.showGeneralError = false;
      this.message = "";

      this.$refs.form.resetValidation();
    },
  },
  watch: {
    classification(val) {
      this.body.classification_id = val.id;
    },
    body: {
      handler(val) {
        if (val.name && val.single_code && val.classification_id) {
          this.valid = true;
        } else {
          this.valid = false;
        }
      },
      deep: true,
    },
  },
};
</script>
